<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<span>{{ realName ? realName : '' }}({{ jobNumber ? jobNumber : '' }})</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label :label="l('标准月薪')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="标准月薪"
							v-model="entity.monthlyPay"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('底薪')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number :min="0" style="width: 100%" placeholder="底薪" v-model="entity.basePay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('主管加给')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="主管加给"
							v-model="entity.managerAdd"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('岗位津贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="岗位津贴"
							v-model="entity.psotAllowance"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('技术津贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="技术津贴"
							v-model="entity.skillAllowance"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('全勤津贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="全勤津贴"
							v-model="entity.fullAllBonus"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('通讯补贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="通讯补贴"
							v-model="entity.communicationSubsidy"
						/>
					</a-col>
					<!--                    <a-col :span="4">-->
					<!--                        <q-label :label="l('保底工资')">-->
					<!--                        </q-label>-->
					<!--                    </a-col>-->
					<!--                    <a-col :span="8">-->
					<!--                        <a-input-number :min="0" style="width: 100%" placeholder="保底工资" v-model="entity.guaranteedPay" />-->
					<!--                    </a-col>-->
					<a-col :span="4">
						<q-label :label="l('预付加班工资')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:min="0"
							style="width: 100%"
							placeholder="预付加班工资"
							v-model="entity.prepaidOtPay"
						/>
					</a-col>
				</a-row>
				<!--                <a-row>-->
				<!--                    <a-col :span="4">-->
				<!--                        <q-label :label="l('预付加班工资')">-->
				<!--                        </q-label>-->
				<!--                    </a-col>-->
				<!--                    <a-col :span="8">-->
				<!--                        <a-input-number :min="0" style="width: 100%" placeholder="预付加班工资" v-model="entity.prepaidOtPay" />-->
				<!--                    </a-col>-->
				<!--                </a-row>-->
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	CreateOrUpdateFixedInfoInput,
	FixedInfoEditDto,
	FixedInfoServiceProxy,
} from '../../../../shared/service-proxies';

export default {
	name: 'edit-fixed-info',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			id: undefined,
			entity: new FixedInfoEditDto(),
			realName: undefined,
			jobNumber: undefined,
		};
	},
	created() {
		this.fullData();
	},
	mounted() {
		this._fixedInfoServiceProxy = new FixedInfoServiceProxy(this.$apiUrl, this.$api);
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._fixedInfoServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.fixedInfo;
				});
		},
		save() {
			let input = new CreateOrUpdateFixedInfoInput();
			input.fixedInfo = this.entity;
			this.loading = true;
			this._fixedInfoServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
