<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<!--筛选条件-->
			<a-row :gutter="[8, 8]">
				<a-col :span="6" class="d-flex align-items-center">
					<span style="text-align: right;margin: 0;flex-shrink: 0;padding-right: 3px">离职日期:</span>
					<span><a-range-picker @change="leaveDateChange" style="width: 100%"/></span>
				</a-col>
				<a-col :span="6" class="d-flex align-items-center">
					<span style="text-align: right;margin: 0;flex-shrink: 0;padding-right: 3px">入职日期:</span>
					<a-range-picker @change="entryDateChange" style="width: 100%" />
				</a-col>
				<a-col :span="4">
					<a-tree-select
						@change="handleChange"
						allowClear
						multiple
						:placeholder="l('Company')"
						style="width: 100%"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						tree-default-expand-all
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						v-model="departmentId"
						allowClear
						style="width:100%"
						multiple
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						@select="SelectDepartmentTree"
						@change="search"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="4">
					<a-select
						show-search
						allowClear
						mode="tags"
						showArrow
						:placeholder="l('员工状态')"
						option-filter-prop="children"
						style="width: 100%"
						v-model="empState"
						@change="search"
					>
						<a-select-option v-for="item in empStateList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row :gutter="[8, 8]">
				<a-col :span="18">
					<a-button type="primary" @click="importExcel" v-if="isGranted('sl_fixed_import')">
						<a-icon type="import" />
						导入
					</a-button>
					<a-button type="primary" @click="Export" v-if="isGranted('sl_fixed_excel')">
						<a-icon type="download" />
						<span>导出</span>
					</a-button>
					<a-button type="primary" @click="detailview" v-if="isGranted('sl_salary_change_view')">
						<a-icon type="idcard" />
						<span>查看变动记录</span>
					</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right">
					<a-input-search
						style="margin-top: 4px;width: 200px"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 2300, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="actions" slot-scope="text, record">
					<a-space>
						<!-- 修改 -->
						<a class="table-edit" @click="Edit(record)" v-if="isGranted('sl_fixed_edit')">
							<a-icon type="edit" />{{ l('Edit') }}</a
						>
					</a-space>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import { CompanyServiceProxy, DepartmentServiceProxy, FixedInfoServiceProxy } from '../../../shared/service-proxies';
import moment from 'moment';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import ImportExcelFixedInfo from './import-excel-fixed-info/import-excel-fixed-info';
import { Dic, fileDownloadService } from '../../../shared/utils';
import EditFixedInfo from './edit-fixed-info/edit-fixed-info';
import SalaryChangeDetailView from '../salary-change/salary-change-detail-view/salary-change-detail-view';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'fixed-info',
	mixins: [AppComponentBase],
	components: { ImportExcelFixedInfo, EditFixedInfo },
	data() {
		return {
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],

			//入职开始日期
			entryStartTime: undefined,
			//入职结束日期
			entryEndTime: undefined,
			//离职开始日期
			leaveStartTime: undefined,
			//离职结束日期
			leaveEndTime: undefined,
			// 用户表格
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					sorter: true,
					width: 100,
					ellipsis: true,
					fixed: 'left',
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					sorter: true,
					width: 100,
					ellipsis: true,
					fixed: 'left',
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '公司',
					dataIndex: 'companyName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: '部门',
					dataIndex: 'department',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'department' },
				},
				{
					title: '岗位',
					dataIndex: 'post',
					sorter: true,
					width: 160,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'post' },
				},
				{
					title: '岗位等级',
					dataIndex: 'postGrade',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'postGrade' },
				},
				{
					title: '员工类型',
					dataIndex: 'empType',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empType' },
				},
				{
					title: '入职日期',
					dataIndex: 'hireDate',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				{
					title: this.l('离职日期'),
					dataIndex: 'termDate',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'termDate' },
				},
				{
					title: '标准月薪',
					dataIndex: 'monthlyPay',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'monthlyPay' },
				},
				{
					title: '底薪',
					dataIndex: 'basePay',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'basePay' },
				},
				{
					title: '主管加给',
					dataIndex: 'managerAdd',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'managerAdd' },
				},
				{
					title: '岗位津贴',
					dataIndex: 'psotAllowance',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'psotAllowance' },
				},
				{
					title: '技术津贴',
					dataIndex: 'skillAllowance',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'skillAllowance' },
				},
				{
					title: '全勤奖金',
					dataIndex: 'fullAllBonus',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'fullAllBonus' },
				},
				{
					title: '通讯补贴',
					dataIndex: 'communicationSubsidy',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'communicationSubsidy' },
				},
				// {
				//     title: "保底工资",
				//     dataIndex: "guaranteedPay",
				//     sorter: true,
				//     ellipsis: true,
				//     align: "center",
				//     scopedSlots: {customRender: "guaranteedPay"}
				// },
				{
					title: '预付加班工资',
					dataIndex: 'prepaidOtPay',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'prepaidOtPay' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					fixed: 'right',
					scopedSlots: { customRender: 'actions' },
					width: 100,
				},
			],
			tableData: [],

			empState: undefined,
			empStateList: [],
		};
	},
	created() {
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._fixedInfoServiceProxy = new FixedInfoServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
	},
	async mounted() {
		try {
			this.loading = true;
			this.empStateList = await Dic.getInstance().getDicAsync('EmpState');
		} catch (e) {
			console.log(e);
		} finally {
			this.loading = false;
		}

		this.companyTreeInit();
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._fixedInfoServiceProxy
				.getPaged(
					this.entryStartTime,
					this.entryEndTime,
					this.comPanyId,
					this.departmentId,
					this.empState,
					this.leaveStartTime,
					this.leaveEndTime,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';
						item.termDate = item.termDate ? moment(item.termDate).format('YYYY-MM-DD') : '';
					});
					this.totalItems = res.totalCount;
					if (this.tableData.length > 0) {
						this.rowSelect(this.tableData[0], 0);
					}
				});
		},

		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.comPanyId = value;
			this.refresh();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},

		//入职范围日期框选择
		entryDateChange(date) {
			if (date.length > 0) {
				this.entryStartTime = date[0];
				this.entryEndTime = date[1];
			} else {
				this.entryStartTime = undefined;
				this.entryEndTime = undefined;
			}
			this.refresh();
		},
		//离职范围日期框选择
		leaveDateChange(date) {
			if (date.length > 0) {
				this.leaveStartTime = date[0];
				this.leaveEndTime = date[1];
			} else {
				this.leaveStartTime = undefined;
				this.leaveEndTime = undefined;
			}
			this.refresh();
		},
		/**
		 * 导入
		 */
		importExcel() {
			modalHelper.create(ImportExcelFixedInfo, {}, { width: '800px' }).subscribe((res) => {
				this.getData();
			});
		},
		/**
		 * 导出
		 */
		Export() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._fixedInfoServiceProxy
				.getToExcelFile(
					this.entryStartTime,
					this.entryEndTime,
					this.comPanyId,
					this.departmentId,
					this.empState,
					this.leaveStartTime,
					this.leaveEndTime,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		/**
		 *编辑
		 */
		Edit(record) {
			modalHelper
				.create(
					EditFixedInfo,
					{
						id: record.id,
						jobNumber: record.jobNumber,
						realName: record.realName,
					},
					{ width: '800px' }
				)
				.subscribe((res) => {
					if (res) {
						this.getData();
					}
				});
		},
		/**
		 * 查看变动记录
		 * @returns {any}
		 */
		detailview() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			modalHelper
				.create(
					SalaryChangeDetailView,
					{
						empId: this.selectedRows[0].empId,
						jobNumber: this.selectedRows[0].jobNumber,
						realName: this.selectedRows[0].realName,
					},
					{ width: '1000px' }
				)
				.subscribe((res) => {});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
